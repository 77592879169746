<template>
  <div class="group_aside_search_page">
    <div class="no_data"
      v-if="no_data"
    >没有找到相关结果</div>
    <!-- 搜索状态 -->
    <div class="search_status"
      v-else
    >
      <div
        v-show="search_friends && search_friends.length"
        class="contact_group group"
      >
        <div
          class="title d-flex align-center cp un_sel"
          @click.stop="openFriends = !openFriends"
        >
          <div
            class="icon mr-5"
            :class="{ active: openFriends }"
          ></div>
          <div
            class="title_name"
          >
            联系人
          </div>
        </div>
        <!--<div class="title">联系人</div>-->
        <template
          v-if="openFriends"
        >
          <template v-for="(item,index) in search_friends">
            <contact-unit
              v-if="index<3"
              class="cp un_sel"
              stick="0"
              :key="item.id"
              :messageData="item"
              @click.native="goSingleDialog(item)"
              :search="true"
              :no_border="true"
            >
              <template v-slot:name >
                <!--  v-if="item.friendRemark.indexOf(search_key) != -1" -->
                <span
                  v-if="highlightKeywords(item.friendRemark, search_key) !== item.friendRemark"
                  v-html="highlightKeywords(item.friendRemark, search_key)"
                >
                  name1
                </span>
                <!-- v-if="item.friendRemark.indexOf(search_key) == -1 && item.userName.indexOf(search_key) != -1" -->
                <span
                  v-else
                  v-html="highlightKeywords(item.userName, search_key)"
                >
                  name1
                </span>
              </template>
            </contact-unit>
          </template>
          <template
            v-if="switch_contact"
          >
            <template v-for="(item,index) in search_friends">
              <contact-unit
                v-if="index>=3"
                class="cp un_sel"
                stick="0"
                :key="item.id"
                :messageData="item"
                @click.native="goSingleDialog(item)"
                :search="true"
                :no_border="true"
              >
                <template v-slot:name >
                  <!--  v-if="item.friendRemark.indexOf(search_key) != -1" -->
                  <span
                    v-html="highlightKeywords(item.friendRemark, search_key)"
                  >
                    name1
                  </span>
                  <!-- v-if="item.friendRemark.indexOf(search_key) == -1 && item.userName.indexOf(search_key) != -1" -->
                  <span
                    v-html="highlightKeywords(item.userName, search_key)"
                  >
                    name1
                  </span>
                </template>
              </contact-unit>
            </template>
          </template>
          <div class="fun_group">
            <div class="box"
              :class="{only_border:search_friends.length<3, on: switch_contact}"
              @click="switch_contact=!switch_contact"
            >
              <div class="icon cp"
                v-if="search_friends.length>3"
              ></div>
              <div
                v-if="search_friends.length>3"
                class="text cp"
              >{{switch_contact?'收起':'展开'}}({{search_friends.length}})</div>
            </div>
          </div>
        </template>
      </div>
      <div
        v-show="search_chat_groups && search_chat_groups.length"
        class="work_team_group group"
      >
        <div
          class="title d-flex align-center cp un_sel"
          @click.stop="openChatGroup = !openChatGroup"
        >
          <div
            class="icon mr-5"
            :class="{ active: openChatGroup }"
          ></div>
          <div
            class="title_name"
          >
            工作组
          </div>
        </div>
        <!--<div class="title">工作组</div>-->
        <template
          v-if="openChatGroup"
        >
          <template v-for="(item,index) in search_chat_groups">
            <work-team-unit
              v-if="index < 3"
              class="cp un_sel"
              :userlist="item.pictures"
              stick="0"
              :item="item"
              :key="item.id"
              :messageData="item"
              :search="true"
              @click.stop.native="goGroupDialog(item)"
              :no_border="true"
            >
              <template v-slot:name>
                <span v-html="highlightKeywords(item.chatName, search_key)">name1</span>
              </template>
              <template v-slot:desc>
                <span v-html="highlightKeywords(`成员: ${item.secondleveContent}`,search_key)"
                  v-if="item.secondleveContent != null"
                ></span>
              </template>
              <!-- <template v-slot:time>
                <span>{{item.createTime | formatDate('HH:mm')}}</span>
              </template> -->
            </work-team-unit>
          </template>
          <template
            v-if="switch_workteam"
          >
            <template v-for="(item,index) in search_chat_groups">
              <work-team-unit
                v-if="index >= 3"
                class="cp un_sel"
                :userlist="item.pictures"
                stick="0"
                :item="item"
                :key="item.id"
                :messageData="item"
                :search="true"
                @click.stop.native="goGroupDialog(item)"
                :no_border="true"
              >
                <template v-slot:name>
                  <span v-html="highlightKeywords(item.chatName, search_key)">name1</span>
                </template>
                <template v-slot:desc>
                  <span v-html="highlightKeywords(`成员: ${item.secondleveContent}`,search_key)"
                    v-if="item.secondleveContent != null"
                  ></span>
                </template>
                <!-- <template v-slot:time>
                  <span>{{item.createTime | formatDate('HH:mm')}}</span>
                </template> -->
              </work-team-unit>
            </template>
          </template>
          <div
            class="fun_group un_sel"
          >
            <div
              class="box"
              :class="{only_border:search_chat_groups.length<3, on: switch_workteam}"
              @click="switch_workteam=!switch_workteam"
            >
              <div
                v-if="search_chat_groups.length > 3"
                class="icon cp"
              ></div>
              <div
                v-if="search_chat_groups.length > 3"
                class="text cp"
              >{{switch_workteam?'收起':'展开'}}({{search_chat_groups.length}})</div>
            </div>
          </div>
        </template>
      </div>
      <div
        class="group template_group"
      >
        <div
          class="title d-flex align-center cp un_sel"
          @click.stop="switchOpenTask"
        >
          <div
            class="icon mr-5"
            :class="{ active: openTask }"
          ></div>
          <div
            class="title_name"
          >
            任务
          </div>
        </div>
        <div
          v-if="search_task && search_task.length && openTask"
          class="template_list"
        >
          <template
            v-for="(item, index) in search_task"
          >
            <div
              class="template_unit cp"
              :key="index"
              @click="doOpen(item)"
            >
              <div class="task_group">
                <div class="color_group"
                  v-if="item.colour"
                >
                  <div
                    class="color"
                    v-for="(item, index) in getColor(item.colour)"
                    :key="index"
                    :style="{'background-color': item}"
                  ></div>
                </div>
                <div class="info_group">
                  <el-tooltip class="item" effect="dark"
                    :content="item.name" placement="bottom-start"
                    :open-delay="600"
                  >
                    <div class="task_name">{{item.name}}</div>
                  </el-tooltip>
                  <div class="from_group">
                    <el-tooltip class="item" effect="dark"
                      :content="item.createusername"
                      placement="bottom-start"
                      :open-delay="600"
                    >
                      <img :src="`${$avatar_url}${item.createuserthumbnail}@!small200`" alt="" class="avatar">
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark"
                      :content="item.from" placement="bottom-start"
                      :open-delay="600"
                    >
                      <div class="chat_name">来源：{{item.from}}</div>
                    </el-tooltip>
                  </div>
                </div>
              </div>

            </div>
          </template>
          <template
            v-if="switch_task && 0"
          >
            <template
              v-for="(item, index) in search_task"
            >
              <div class="template_unit cp"
                v-if="index>=3"
                :key="index"
                @click="doOpen(item)"
              >
                <div class="task_group">
                  <div class="color_group"
                    v-if="item.colour"
                  >
                    <div
                      class="color"
                      v-for="(item, index) in getColor(item.colour)"
                      :key="index"
                      :style="{'background-color': item}"
                    ></div>
                  </div>
                  <div class="info_group">
                    <el-tooltip class="item" effect="dark"
                      :content="item.name" placement="bottom-start"
                      :open-delay="600"
                    >
                      <div class="task_name">{{item.name}}</div>
                    </el-tooltip>
                    <div class="from_group">
                      <el-tooltip class="item" effect="dark"
                        :content="item.createusername"
                        placement="bottom-start"
                        :open-delay="600"
                      >
                        <img :src="`${$avatar_url}${item.createuserthumbnail}@!small200`" alt="" class="avatar">
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark"
                        :content="item.from" placement="bottom-start"
                        :open-delay="600"
                      >
                        <div class="chat_name">来源：{{item.from}}</div>
                      </el-tooltip>
                    </div>
                  </div>
                </div>

              </div>
            </template>
          </template>
        </div>
        <template
          v-if="loadingTask"
        >
          <loading-icon
            size="20px"
          ></loading-icon>
        </template>
        <div
          v-if="search_task && search_task.length && openTask"
          class="fun_group"
        >
          <template
            v-if="0"
          >
            <div
              v-if="!switch_task"
              class="box"
              :class="{
                only_border: search_task.length<3,
                on: switch_task
              }"
              @click="switch_task=!switch_task"
            >
              <div
                v-if="search_task.length > 3"
                class="icon cp"
              ></div>
              <div
                v-if="search_task.length > 3"
                class="text cp"
              >{{switch_task?'收起':'展开'}}</div>
            </div>
          </template>
          <div
            v-if="!no_more_task && search_task && search_task.length >= 20 && !loadingTask"
            class="box un_sel"
            @click="getMoreSearchTask()"
          >
            <div class="icon cp"></div>
            <div class="text cp">加载更多</div>
          </div>
        </div>
      </div>
      <div
        v-loading="loadChatGroupUser"
        v-show="search_chat_groups && search_chat_groups.length"
        class="work_team_group group"
      >
        <div
          class="title d-flex align-center cp un_sel"
          @click.stop="switchOpenChatGroupUser"
        >
          <div
            class="icon mr-5"
            :class="{ active: openChatGroupUser }"
          ></div>
          <div
            class="title_name"
          >
            工作组成员
          </div>
        </div>
        <!--<div class="title">工作组</div>-->
        <template
          v-if="openChatGroupUser"
        >
          <template
            v-for="(item) in chatGroupUser"
          >
            <work-team-unit
              class="cp un_sel"
              :userlist="item.pictures"
              stick="0"
              :item="item"
              :key="item.id"
              :messageData="item"
              :search="true"
              @click.stop.native="goGroupDialog(item)"
              :no_border="true"
            >
              <template v-slot:name>
                <span v-html="highlightKeywords(item.chatName, search_key)">name1</span>
              </template>
              <template v-slot:desc>
                <span v-html="highlightKeywords(`成员: ${item.secondleveContent}`,search_key)"
                      v-if="item.secondleveContent != null"
                ></span>
              </template>
              <!-- <template v-slot:time>
                <span>{{item.createTime | formatDate('HH:mm')}}</span>
              </template> -->
            </work-team-unit>
          </template>
        </template>
      </div>
      <div class="group other_group"  v-if="0">
        <div class="title">模板</div>
        <div class="other_content">
          <img src="@/assets/images/common/task_search_other.png" alt class="img" />
          <div class="info_group">
            <div class="info_title">搜索模板记录</div>
            <div class="info_subtitle">查找(包含'1391')的模板</div>
          </div>
        </div>
        <div class="fun_group">
          <div class="box"></div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import ContactUnit from "@/components/sidebar/contactUnit.vue";
import WorkTeamUnit from "@/components/sidebar/workTeamUnit.vue";
import LoadingIcon from '@/components/basic/loadingIcon'
export default {
  components: {
    ContactUnit,
    WorkTeamUnit,
    LoadingIcon
  },
  data() {
    return {
      // messageBoxList: null,
      startId: 1,
      no_more_task: false,
      switch_contact: false,
      switch_workteam: false,
      switch_task: false,
      openTask: false,
      noTask: false,
      initTask: false,
      loadingTask: false,
      openFriends: true,
      openChatGroup: true,
      openChatGroupUser: false,
      chatGroupUser: [],
      loadChatGroupUser: false,
      initChatGroupUser: false,
    };
  },
  computed: {
    // 消息盒子
    messageBoxList() {
      return this.$store.state.message_box_list;
    },
    // 搜索
    search_chat_groups(){
      return this.$store.state.common.search_chat_groups;
    },
    search_friends(){
      return this.$store.state.common.search_friends;
    },
    search_key(){
      return this.$store.state.common.search_key;
    },
    search_task(){
      return this.$store.state.search_task;
    },
    no_data(){
      const { noChat, noFriends, noAnyTask } = this
      return noChat && noFriends && noAnyTask
      return false
      const { search_chat_groups, search_friends, search_task } = this
      const no_chat  = !(search_chat_groups && search_chat_groups.length)
      const no_friends = !(search_friends && search_friends.length)
      const no_task = !(search_task && search_task.length)
      if (no_chat && no_friends && no_task) {
        return true
      }
      return false
    },
    noChat() {
      const { search_chat_groups } = this
      return !(search_chat_groups && search_chat_groups.length)
    },
    noFriends() {
      const { search_friends } = this
      return !(search_friends && search_friends.length)
    },
    noAnyTask() {
      const { initTask, loadingTask, search_task } = this
      return initTask && !loadingTask && !(search_task && search_task.length)
    }
  },
  watch: {
    /**
     * 监听消息盒子,如果为null则获取
     */
    messageBoxList: {
      handler: function (newval) {
        if (newval === null) {}
      },
      deep: true,
    },
    search_chat_groups: {
      handler: function(){},
      immediate: true,
      deep: true,
    },
    search_friends: {
      handler: function(){},
      deep: true,
    },
    search_key: function() {
      this.switch_contact = false
      this.switch_workteam = false
      this.switch_task = false
      this.no_more_task = false
      this.initTask = false
      this.openTask = false
      this.openFriends = true
      this.openChatGroup = true
      this.init()
    },
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async switchOpenChatGroupUser () {
      this.openChatGroupUser = !this.openChatGroupUser
      if (this.openChatGroupUser) {
        if (this.chatGroupUser && this.chatGroupUser.length) {
          return
        }
        const res = await this.getChatGroupUser()

      }
    },
    async getChatGroupUser () {
      const { search_keyword } = this.$store.state
      // //console.log(search_keyword)
      if (search_keyword) {
        const data = {
          param: search_keyword
        }
        this.loadChatGroupUser = true
        const res = await this.$global.doPost({
          url: '/project/searchWebChatUser',
          data,
          notice: '搜索任务失败'
        })
        this.loadChatGroupUser = false

        // //console.log(res.contents, res.contents.tasks)
        if (res.message === 'success') {
          this.$set(
            this,
            'chatGroupUser',
            res.contents.chatGoups
          )
        }
      }

    },
    /**
     * 打开任务搜索
     * @returns {Promise<void>}
     */
    async switchOpenTask() {
      this.openTask = !this.openTask
      if (this.openTask) {
        // 查询
      }
      if (this.initTask === false) {
        await this.getMoreSearchTask()
        this.initTask = true
      }
      //console.log(this.initTask)
    },
    // 搜索高亮
    highlightKeywords(str, keywords) {
      return this.$tools.highlightKeywords(str, keywords);
    },
    /**
     * @description: 设置消息盒子(store)
     * @param {type}
     * @return:
     */
    async set_message_box_list() {
      if (this.messageBoxList == null) {
        this.$store.dispatch("get_message_box_list");
      }
    },
    /**
     * 初始化
     */
    async init() {
      if (!this.messageBoxList || !this.messageBoxList.length) {
        this.set_message_box_list()
      }
      // 如果没有好友或者工作组 自动查找任务
      const { noChat, noFriends } = this
      if (noChat && noFriends) {
        await this.switchOpenTask()
      }
    },
    /**
     * 单聊
     */
    goSingleDialog(item) {
      //console.log(item, '单人')
      this.checkMessageBoxList(item, 0);
      this.$router.push({
        name: "fun_task_dialog_single",
        params: { id: this.$tools.btoa(item.friendUserId) },
      });
      this.$store.commit('common_set_search_switch', false);
      const chatTypeItem = Object.assign(
        {},
        item,
        {
          chatId: item.friendUserId, // 对方id
          chatType: 0
        }
      )
      this.$store.commit("set_message_box_unit", chatTypeItem)
    },
    /**
     * 群聊
     */
    goGroupDialog(item) {
      // //console.log(item)
      this.checkMessageBoxList(item, 1);
      this.$router.push({
        name: "fun_task_dialog_group",
        params: { id: this.$tools.btoa(item.chatId) },
      })
      this.$store.commit('common_set_search_switch', false);
      this.$store.commit("set_task_group_dialog_title_data", item);
      this.$store.commit("set_workteam_data", item)
      //console.log(item)
      const chatTypeItem = Object.assign(
        {},
        item,
        {
          chatType: 1
        }
      )
      this.$store.commit("set_message_box_unit", chatTypeItem) // 替代上两项
      this.$store.dispatch("close_all_popover"); // 关闭所有弹出框
      this.$store.commit("set_chat_id", item.chatId); // 设置会话id
      if(item.unreadMessageNum && item.unreadMessageNum != 0){
        // //console.log('置空')
        // 将当前chatid未读消息置空
        this.$http.request('/projectTask/unreadTozero', {chat_id:item.chatId, chat_type:item.chatType});
        item.unreadMessageNum = 0;
      }
    },

    checkMessageBoxList(data, type){
      let chatId;
      if(type == 0){
        chatId = data.friendUserId;
      }
      if(type == 1){
        chatId = data.chatId;
      }
      // let chatId = this.
       // 查询当前消息盒子列表是否有当前用户的会话 如果没有创建
      let message_box_list = this.$tools.deepClone(this.messageBoxList);
      let hasDialog = message_box_list.find((item)=>{
        return item.chatId == chatId;
      })
      // 不存在
      if(!hasDialog){
        let message_box_list_unit = {
          // masterUserid: this.$tools.getUserId(),
          chatType: type,
          chatId,
          messageContent: '',
          // name: this.userData.friendRemark || this.userData.userName,
          name: data.friendRemark || data.userName || data.chatName,
          photo: data.thumbnail || data.photo,
        }
        if(type == 0){
          message_box_list_unit.masterUserid = chatId;
        }
        this.$store.commit('set_message_box_unit',message_box_list_unit );
        let not_top_index = message_box_list.findIndex( item => {
          return !item.istop;
        })
        if(not_top_index != -1){
          message_box_list.splice(not_top_index, 0, message_box_list_unit);
        }else{
          message_box_list.unshift(message_box_list_unit);
        }
        this.$store.commit('set_message_box_list', message_box_list);
      }
      // this.$router.push({name:'fun_task_dialog_single', params:{id: this.$route.params.id}})
    },
    /**
     * 打开任务
     */
    doOpen(item){
      this.$api.moduleTask.openTask({taskId: item.id, config: {
        edit: true
      }});
      return
      this.$store.dispatch('openTaskBox', {
        taskId: item.id,
        chatId: item.chatId,
        chatType: item.chatType,
      });
    },
    /**
     * @description: 获取颜色数组
     * @param {*} color
     * @return {*}
     */
    getColor(color){
      if(!color){
        return null;
      }
      let arr = [];
      arr = color.split(',');
      return arr;
    },
    async getMoreSearchTask () {
      if (this.loadingTask) {
        return
      }
      this.loadingTask = true
      const { search_task } = this
      const { search_keyword } = this.$store.state
      // //console.log(search_keyword)
      if (search_keyword) {
        const data = {
          param: search_keyword
        }
        if (search_task && search_task.length) {
          data.startId = search_task[search_task.length-1].id
        }
        const res = await this.$global.doPost({
          url: '/project/searchTask',
          data,
          notice: '搜索任务失败'
        })
        if (res.message === 'success') {
          let new_search_task = []
          if (search_task && search_task.length) {
            new_search_task = search_task.concat(res.contents.tasks)
          } else {
            new_search_task = res.contents.tasks
          }
          if (res.contents.tasks.length < 20) {
            this.no_more_task = true
          }
          this.$store.commit('set_search_task', new_search_task)
        }
      }
      this.loadingTask = false
    }
  },
};
</script>
<style lang="scss" scoped>
.group_aside_search_page{
  @include scrollbar;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  .no_data{
    @include flex;
    @include bbox;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    padding-left:1px;
    color:#999;
    height: 100%;
    width: 100%;
  }
  .search_status {
    width: 100%;
    .group {
      width: 100%;
      .title {
        @include bbox;
        padding: {
          left: 17px;
          top: 18px;
          bottom: 10px;
        }
        font-size: 14px;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        .icon{
          @include background(8px 10px);
          width: 10px;
          height: 10px;
          // background-size: 100%;
          background-image: url(~@/assets/images/common/workteam_packup.png);
          cursor: pointer;
          &.active{
            background-size: 10px 8px;
            background-image: url(~@/assets/images/common/workteam_extend.png);
          }
        }
      }
      &:last-child {
        .fun_group {
          .box {
            border-bottom: none;
          }
        }
      }
      .fun_group {
        @include bbox;
        padding-left: 17px;
        padding-top: 14px;

        .box {
          @include flex;
          width: 100%;
          border-bottom: 2px solid $border_color;
          padding-bottom: 19px;
          &.only_border{
            padding-bottom: 0;
          }
          &.on{
            .icon{
              background: {
                size: 100%;
                image: url(~@/assets/images/common/search_packup.png);
              }
            }
          }
          .icon {
            flex-shrink: 0;
            width: 10px;
            height: 8px;
            background: {
              size: 100%;
              image: url(~@/assets/images/common/search_extend.png);
            }
          }
          .text {
            font-size: 12px;

            font-weight: 400;
            color: rgba(0, 84, 166, 1);
            padding-left: 6px;
          }
        }
      }
    }
    .template_group {
      .template_unit {
        @include bbox;
        @include single_line_intercept;
        @include transition;
        width: 100%;
        font-size: 14px;

        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        padding: 10px 17px;
        &:hover{
          background-color: #d4d4d4;
        }
      }
      .task_group{
        @include flex;
        @include bbox;
        width: 100%;
        align-items: stretch;
      }
      .color_group{
        @include flex;
        flex-direction: column;
        flex-shrink: 0;
        margin-right: 9px;
        width: 4px;

        .color{
          width: 100%;
          flex: 1;
        }
      }
      .task_name{
        @include single_line_intercept;
        font-size: 14px;
        color:#333;
        padding-bottom: 11px;
      }
      .info_group{
        width: 100%;
      }
      .from_group{
        @include flex;
      }
      .avatar{
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 2px;
        margin-right: 11px;
        flex-shrink: 0;
      }
      .chat_name{
        @include single_line_intercept;
        width: 100%;
        text-decoration: underline;
        color: #999;
        font-size: 12px;
      }
    }
    .other_group {
      .other_content {
        @include bbox;
        @include flex;
        width: 100%;
        padding: 15px 17px;
        padding-right: 13px;
        align-items: stretch;
        overflow: hidden;
        .img {
          width: 44px;
          height: 44px;
          display: block;
          flex-shrink: 0;
        }
        .info_group {
          @include flexsb;
          flex-direction: column;
          width: 100%;
          flex: 1;
          align-items: flex-start;
          padding-left: 15px;
          .info_title {
            font-size: 14px;

            font-weight: 400;
            color: rgba(51, 51, 51, 1);
          }
          .info_subtitle {
            @include single_line_intercept;
            font-size: 12px;

            font-weight: 400;
            color: rgba(153, 153, 153, 1);
          }
        }
      }
    }
  }
}
</style>
